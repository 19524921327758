import { Button, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CreateLockModal } from "../../components/modals/lock/createLockModal";
import { api } from "@/api";
import { Lock } from "@/api/locks";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaLock, FaPencil, FaPlus } from "react-icons/fa6";
import { EditLockModal } from "../../components/modals/lock/editLockModal";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "@/hooks/useErrorHandling";
import { LocationChip } from "../../components/chips/locationChip";
import { ColumnType } from "../../components/tables/tableTypes";

interface LockLocationInterface {
  id: number;
  display_name: string;
}

type LockLocation = LockLocationInterface | null;

export function LocksPage() {
  const [rawLocks, setRawLocks] = useState<Lock[] | null>(null);
  const [locks, setLocks] = useState<Lock[] | null>(null);
  const [lock, setLock] = useState<Lock | null>(null);
  const [tabs, setTabs] = useState<LockLocation[] | null>(null);
  const [activeTab, setActiveTab] = useState<any>("all");
  const navigate = useNavigate();

  const handleError = useErrorHandling();
  
  const createLockModalDisclosure = useDisclosure();
  const editLockModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editLock = (lock: Lock) => {
    setLock(lock);
    editLockModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.locks.list()
      .then(response => {
        setRawLocks(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createLockModalDisclosure.isOpen,
    editLockModalDisclosure.isOpen
  ]);

  useEffect(() => {
    const locations: LockLocation[] = [];

    rawLocks?.forEach(lock => {
      if (lock.location) {
        if (!locations.find(loc => loc?.id == lock.location?.id)) locations.push(lock.location);
      }
    });

    setTabs(locations);
  }, [rawLocks]);

  useEffect(() => {
    if (activeTab === "all") return setLocks(rawLocks);
    const filteredLocks: Lock[] = [...(rawLocks?.filter(lock => lock.location?.id == activeTab) || [])];
    return setLocks(filteredLocks);
  }, [rawLocks, activeTab]);

  return (
    <>
      <CreateLockModal disclosure={createLockModalDisclosure} />
      <EditLockModal disclosure={editLockModalDisclosure} lock={lock} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Замки</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
                <FaExpand />
              </Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createLockModalDisclosure.onOpen()}
              >
                Создать замок
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2 mb-4">
            <Tabs color="primary" variant="bordered" selectedKey={activeTab} onSelectionChange={setActiveTab}>
              <Tab key="all" title="Все локации" />
              {tabs?.map(tab => (
                <Tab key={tab?.id} title={tab?.display_name}></Tab>
              ))}
            </Tabs>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              fullscreenDisclosure={fullscreenDisclosure}
              columns={[
                {
                  label: "Название замка",
                  key: "display_name",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Локация",
                  key: "location",
                  type: ColumnType.Custom,
                  render(_value, row) {
                    return <LocationChip locationId={row.location?.id} />;
                  },
                },
                {
                  label: "Система",
                  key: "lock_system",
                  type: ColumnType.LockSystem,
                  sortable: true
                },
                {
                  label: "Сервисный ID",
                  key: "lock_service_id",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editLock(row); }
                    },
                    {
                      icon: <FaLock />,
                      onClick: (_value, row) => { navigate(`/dashboard/locks/${row.id}`); }
                    }
                  ]
                }
              ]}
              data={locks || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "display_name",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
