import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { getIdFromCategoryHandbook, getIdFromOfficeHandbook, getIdFromUserHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";

interface CreateTicketForm {
  title: string;
  description: string;
  category: number;
  office: number;
  user: number;
}

const createTicketFields: InputField[] = [
  {
    label: "Тема обращения",
    name: "title",
    placeholder: "Введите тему обращения",
    type: "text",
    isRequired: true
  }, {
    label: "Описание",
    name: "description",
    placeholder: "Введите описание обращения",
    type: "text"
  }, {
    label: "Категория",
    name: "category",
    placeholder: "",
    type: "category",
    isRequired: true
  }, {
    label: "Офис",
    name: "office",
    placeholder: "",
    type: "office",
    isRequired: true
  }, {
    label: "Пользователь",
    name: "user",
    placeholder: "",
    type: "user",
    isRequired: true
  }
]

export function CreateTicketModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: CreateTicketForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const user = await getIdFromUserHandbook(String(data.user));
      const office = await getIdFromOfficeHandbook(String(data.office));
      const category = await getIdFromCategoryHandbook(String(data.category));

      api.tickets.create({
        title: data.title,
        category: Number(category),
        description: data.description,
        status: "open",
        office: Number(office),
        user: Number(user)
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание тикета" modalErrors={modalErrors} fields={createTicketFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}