import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface RentPlan {
  office_id: number;
  office_name: string;
  price: number;
  deposit: number;
  rent_id: number | null;
  renter_id: number | null;
  renter_name: string | null;
  workplace_count: number;
  office_floor: number;
  location_name: string;
  location_id: string;
  price_per_workplace: number;
  potential_earning_price: number;
  potential_workspace_earning_price: number;
  price_per_sqm: number;
  potential_price_per_sqm: number;
}

export interface ActualPayment {
  office_id: number;
  office_name: string;
  total_paid: number | null;
  renter_id: number;
  renter_name: string;
  billing_id: number;
}

export interface PotentialEarning {
  location_id: number;
  location_name: string;
  potential_earnings: number;
}

export interface DebtSummary {
  office_id: number;
  office_name: string;
  debt: number;
}

export interface SummaryReport {
  rent_plan: RentPlan[];
  actual_payments: ActualPayment[];
  potential_earnings: PotentialEarning[];
  debt_summary: DebtSummary[];
}

export function AnalyticsAPI(baseUrl: string) {
  return {
    summary: async (period: string, year: string = "2024", location: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/analytics/summary?business_profile_id=${businessProfileId}&period=${period}&year=${year}&location_id=${location}`, undefined, true) as ApiResponse<SummaryReport>;
    }
  }
}
