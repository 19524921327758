import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getIdFromLocationHandbook, getIdFromLockHandbook } from "@/utils/getIdFromHandbooks.ts";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";
import { SharedLock } from "@/api/sharedLocks";

interface IEditSharedLockForm {
  lock_id: number;
  location_id: number;
}

const editSharedLockFields: InputField[] = [
  {
    label: "Замок",
    name: "lock_id",
    placeholder: "-",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location_id",
    placeholder: "-",
    type: "location",
    isRequired: true
  }
]

export function EditSharedLockModal({ disclosure, lock }: { disclosure: ReturnType<typeof useDisclosure>, lock: SharedLock | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: IEditSharedLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const lockId = await getIdFromLockHandbook(String(data.lock_id));
      const locationId = await getIdFromLocationHandbook(String(data.location_id));

      api.sharedLocks.update(lock!.id, {
        lock_id: Number(lockId),
        location_id: Number(locationId)
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.sharedLocks.delete(lock!.id)
        .then(_data => {
          disclosure.onClose();
          navigate("/dashboard/sharedLocks/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование общего замка"
      fields={editSharedLockFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        lock_id: lock?.lock_id,
        location_id: lock?.location_id,
      }} />
  );
}