import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { LockSystem } from "@/api/lockSystems/lockSystems.ts";

export function NewLockSystemHandbook(props: AutocompleteProps<LockSystem>) {
  const items = useAppSelector(state => state.handbooks.lockSystem);

  return (
    <Autocomplete
      defaultItems={items}
      label="Система замков"
      placeholder="Выберите систему замков"
      variant="bordered"
      {...props}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}

export function LockSystemHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.lockSystem);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Система замков"
      placeholder="Выберите систему замков"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}