import { Button, Input } from "@nextui-org/react";
import { useForm } from "react-hook-form";
// import { toast, ToastContainer } from "react-toastify";
// import { api } from "@/api";
// import { useNavigate } from "react-router-dom";
// import useErrorHandling from "@/hooks/useErrorHandling";

interface PasswordRecoveryPromptForm {
  login: string;
}

export function NewResetPage() {
  // const navigate = useNavigate();
  // const handleError = useErrorHandling();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordRecoveryPromptForm>({
    defaultValues: {},
  });

  const resetPassword = (_data: any) => {
    return null;
  };

  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg max-lg:min-h-dvh flex flex-col p-4">
          <div className="mb-2">
            <img src="/logo.svg" width={"128"}></img>
          </div>
          <div className="mb-2">
            <span className="font-medium text-xl">Кабинет арендатора</span>
          </div>
          <div className="mb-4">
            <span>Если к имени пользователя, указанному Вами, был привязан номер телефона, мы отправим Вам СМС с кодом для восстановления аккаунта.</span>
          </div>
          <div className="max-lg:flex-grow"></div>
          <div className="flex flex-col gap-2 justify-self-end h-full">
            <form className="flex flex-col gap-2 h-full" onSubmit={handleSubmit(resetPassword)}>
              <Input
                label="Имя пользователя"
                placeholder="Введите логин"
                variant="bordered"
                type="text"
                {...register("login", { required: true })}
                className="mb-2"
                isInvalid={!!errors["login"]}
                errorMessage={errors["login"]?.message?.toString()}
              />
              <div className="max-lg:flex-grow"></div>
              <Button color="primary" className="w-full font-semibold" type="submit">
                Восстановить
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
