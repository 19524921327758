import { Button, useDisclosure } from "@nextui-org/react";
import { FaPlus, FaPrint } from "react-icons/fa6";
import { CreateTemplateModal } from "../../components/modals/documents/createTemplate";
import { useEffect, useState } from "react";
import { api } from "@/api";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { Template } from "@/api/templates";
import { useNavigate } from "react-router-dom";
import { ColumnType } from "../../components/tables/tableTypes";

export function DocumentTemplatesPage() {
  const [documents, setDocuments] = useState<Template[]>();
  const loadTemplateDisclosure = useDisclosure();
  const fullscreenDisclosure = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    api.documents.list()
      .then(res => {
        console.log(res);
        setDocuments(res.data);
      })
      .catch(err => {
        toast.error("Произошла ошибка при загрузке шаблонов!");
        console.error(err);
      });
  }, [loadTemplateDisclosure.isOpen]);

  const fillTemplate = (row: Template) => {
    navigate("/dashboard/documents/" + row.id + "/fill");
  }

  return (
    <>
      <CreateTemplateModal disclosure={loadTemplateDisclosure} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Документы</span>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button
              startContent={<FaPlus />}
              color="primary"
              variant="solid"
              className="max-w-fit"
              onClick={() => loadTemplateDisclosure.onOpen()}
            >
              Загрузить шаблон
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название шаблона",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaPrint />,
                    onClick: (_value, row) => { fillTemplate(row) }
                  }
                ]
              }
            ]}
            data={documents || []}
            rowsPerPage={10}
            defaultSortDescriptor={{
              column: "display_name",
              direction: "ascending"
            }}
          />
        </div>
      </div>
    </>
  );
};