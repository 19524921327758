import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { Lock } from "@/api/locks";

export function NewLockHandbook(props: AutocompleteProps<Lock>) {
  const items = useAppSelector(state => state.handbooks.lock);

  return (
    <Autocomplete
      defaultItems={items}
      label="Замок"
      placeholder="Выберите замок"
      variant="bordered"
      {...props}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      }
    </Autocomplete>
  );
}

export function LockHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.lock);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Замок"
      placeholder="Выберите замок"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}