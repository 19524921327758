import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { Renter } from "@/api/renters";

export function NewRenterHandbook(props: AutocompleteProps<Renter>) {
  const items = useAppSelector(state => state.handbooks.renter);

  return (
    <Autocomplete
      defaultItems={items}
      label="Арендатор"
      placeholder="Выберите арендатора"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function RenterHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.renter);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Арендатор" 
      placeholder="Выберите арендатора"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}