import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { api } from "@/api";
import { UserRole } from "@/api/userRoles/userRoles.ts";
import { useDisclosure, Button } from "@nextui-org/react";
import { FaExpand } from "react-icons/fa6";
import useErrorHandling from "@/hooks/useErrorHandling";
import { ColumnType } from "../../components/tables/tableTypes";

export function UserRolePage() {
  const [roles, setRoles] = useState<UserRole[] | null>(null);

  const handleError = useErrorHandling();

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.userRoles.list()
      .then(response => {
        setRoles(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, []);

  return (
    <div className="p-4">
      <div className="flex flex-col gap-2 mb-2">
        <div className="flex flex-row gap-4 items-center mb-4">
          <span className="font-bold text-2xl">Роли пользователей</span>
          <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-2">
        <TableBuilder
          fullscreenDisclosure={fullscreenDisclosure}
          columns={[
            {
              label: "Название роли",
              key: "name",
              type: ColumnType.String,
              sortable: true
            }
          ]}
          data={roles || []}
          rowsPerPage={10}
          defaultSortDescriptor={{
            column: "name",
            direction: "ascending"
          }}
        />
      </div>
    </div>
  );
}
