import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { Billing } from "@/api/billing";
import { EditBillModal } from "../modals/bill/editBillModal";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

export function PaymentChip({ displayAmount, paymentId, plannedAmount, fallbackCallback = () => {} }: { displayAmount: string, paymentId: number, plannedAmount: number, fallbackCallback?: () => void }) {
  const [item, setItem] = useState<Billing | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.payments);

  useEffect(() => {
    setItem(items.find(el => el.id == paymentId) || null);
  }, [paymentId, items]);

  return (
    <>
      <EditBillModal disclosure={disclosure} billing={item} />
      {item !== null && (
        <Chip
          className="cursor-pointer"
          onClick={disclosure.onOpen}
          variant="dot"
          color={+displayAmount < plannedAmount ? 
            'warning' : 'success'
          }
        >{ displayAmount }</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default" className="cursor-pointer" onClick={fallbackCallback}>{ displayAmount }</Chip>
      )}
    </>
  );
};