import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"
import { OfficeLocation } from "../locations";

export interface AdminUser {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  telegram: number;
}

export interface BusinessProfile {
  id: number;
  display_name: string;
  inn: string;
  ogrn: string;
  legal_address: string;
}

export interface BusinessProfileCreate {
  display_name: string;
  inn: string;
  ogrn: string;
  legal_address: string;
}

export interface BusinessProfileUpdate {
  display_name: string;
  inn: string;
  ogrn: string;
  legal_address: string;
}

export function BusinessProfilesAPI(baseUrl: string) {
  return {
    list: async () => {
      return await crud.get(`${baseUrl}/admin/business-profiles/`, undefined, true) as ApiResponse<BusinessProfile[]>;
    },
    get: async (id: number) => {
      return await crud.get(`${baseUrl}/admin/business-profiles/${id}`, undefined, true) as ApiResponse<BusinessProfile>;
    },
    create: async (profileData: BusinessProfileCreate) => {
      return await crud.post(`${baseUrl}/admin/business-profiles/`, profileData, true) as ApiResponse<BusinessProfile>;
    },
    update: async (id: number, profileData: BusinessProfileUpdate) => {
      return await crud.put(`${baseUrl}/admin/business-profiles/${id}`, profileData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      return await crud.delete(`${baseUrl}/admin/business-profiles/${id}`, true) as ApiResponse<{}>;
    },
    getLocations: async (id: number) => {
      return await crud.get(`${baseUrl}/admin/business-profiles/${id}/locations`, undefined, true) as ApiResponse<OfficeLocation[]>;
    },
    generateInviteLink: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/business-profiles/generate-invite-link/?business_profile_id=${businessProfileId}`, null, true) as { result: string; invite_hash: string; };
    },
    getAdministrators: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/business-profiles/${businessProfileId}/administrators/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminUser[]>;
    }
  }
}
