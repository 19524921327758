import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { EditRentedOfficeModal } from "../modals/office/editRentedOfficeModal";
import { RentedOffice } from "@/api/rentedOffices";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

export function RentedOfficeChip({ displayName, rentedOfficeId, fallbackCallback = () => {} }: { displayName: string, rentedOfficeId: number, fallbackCallback?: () => void }) {
  const [item, setItem] = useState<RentedOffice | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.rentedOffices);

  useEffect(() => {
    setItem(items.find(el => el.id == rentedOfficeId) || null);
  }, [rentedOfficeId, items]);

  return (
    <>
      <EditRentedOfficeModal disclosure={disclosure} office={item!} />
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{ displayName }</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default" className="cursor-pointer" onClick={fallbackCallback}>{ displayName }</Chip>
      )}
    </>
  );
};