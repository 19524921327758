import { useForm } from "react-hook-form";
import { Button, Input } from "@nextui-org/react";
// import { api } from "@/api";
// import { useNavigate, useParams } from "react-router-dom";
// import useErrorHandling from "@/hooks/useErrorHandling";

interface PasswordRecoveryVerifyForm {
  code: string;
  password: string;
}

export function NewVerifyPage() {
  // const navigate = useNavigate();
  // const { username } = useParams() as { username: string };
  // const handleError = useErrorHandling();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordRecoveryVerifyForm>({
    defaultValues: {},
  });

  const resetPassword = (_data: any) => {
    return null;
  };

  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg max-lg:min-h-dvh flex flex-col p-4">
          <div className="mb-2">
            <img src="/logo.svg" width={"128"}></img>
          </div>
          <div className="mb-2">
            <span className="font-medium text-xl">Кабинет арендатора</span>
          </div>
          <div className="max-lg:flex-grow"></div>
          <div className="flex flex-col gap-2 justify-self-end h-full">
            <form className="flex flex-col gap-2 h-full" onSubmit={handleSubmit(resetPassword)}>
              <Input
                label="Введите код"
                placeholder="Введите код"
                variant="bordered"
                type="text"
                {...register("code", { required: true })}
                className="mb-2"
                isInvalid={!!errors["code"]}
                errorMessage={errors["code"]?.message?.toString()}
              />
              <Input
                label="Введите новый пароль"
                placeholder="Введите новый пароль"
                variant="bordered"
                type="text"
                {...register("password", { required: true })}
                className="mb-2"
                isInvalid={!!errors["password"]}
                errorMessage={errors["password"]?.message?.toString()}
              />
              <div className="max-lg:flex-grow"></div>
              <Button color="primary" className="w-full font-semibold" type="submit">
                Восстановить
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
