import rootStore from "@/app/store";
import { AdminUser } from "../businessProfiles";
import crud, { ApiResponse } from "../crud"

export interface AdminPermission {
  id: number;
  name: string;
  display_name: string;
}

export function AdministratorsAPI(baseUrl: string) {
  return {
    listPermissions: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/administrators/permissions/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminPermission[]>;
    },
    getAdminById: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/administrators/${id}/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminUser>;
    },
    getAdminPermissionsById: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/administrators/${id}/permissions/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminPermission[]>;
    },
    updateAdminPermissionsById: async (id: number, permissions: number[]) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/administrators/${id}/permissions/?business_profile_id=${businessProfileId}`, { permissions }, true) as ApiResponse<AdminUser>;
    },
    unlinkAdminFromBussinessProfile: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/administrators/${id}/deassign-business-profile/${businessProfileId}/?business_profile_id=${businessProfileId}`, true) as ApiResponse<AdminUser>;
    }
  }
}
