import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CreateOfficeTypeModal } from "../../components/modals/office/createOfficeTypeModal";
import { EditOfficeTypeModal } from "../../components/modals/office/editOfficeTypeModal";
import { OfficeType } from "@/api/officeTypes";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { ColumnType } from "../../components/tables/tableTypes";

export function OfficeTypesPage() {
  const [officeTypes, setOfficeTypes] = useState<OfficeType[] | null>(null);
  const [officeType, setOfficeType] = useState<OfficeType | null>(null);

  const handleError = useErrorHandling();

  const createOfficeTypeModalDisclosure = useDisclosure();
  const editOfficeTypeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editOfficeType = (officeType: OfficeType) => {
    setOfficeType(officeType);
    editOfficeTypeModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.officeTypes.list()
      .then(response => {
        setOfficeTypes(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createOfficeTypeModalDisclosure.isOpen,
    editOfficeTypeModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateOfficeTypeModal disclosure={createOfficeTypeModalDisclosure} />
      <EditOfficeTypeModal disclosure={editOfficeTypeModalDisclosure} officeType={officeType} />
      <div className="p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-bold text-2xl">Типы офисов</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <Button startContent={<FaPlus />} color="primary" variant="solid" className="max-w-fit" onClick={() => createOfficeTypeModalDisclosure.onOpen()}>Создать тип офиса</Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название типа",
                key: "display_name",
                type: ColumnType.String
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editOfficeType(row) }
                  }
                ]
              }              
            ]}
            data={officeTypes || []}
            rowsPerPage={10}
            defaultSortDescriptor={{
              column: "display_name",
              direction: "ascending"
            }}
          />
        </div>
      </div>
    </>
  );
}
