import { useDisclosure, Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Renter } from "@/api/renters";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { toast } from "react-toastify";
import { EditRenterModal } from "@/app/components/modals/renter/editRenterModal.tsx";
import { UserChip } from "@/app/components/chips/userChip.tsx";

export function RenterUsersPage() {
  const { id } = useParams();
  const [renter, setRenter] = useState<Renter | null>(null);

  const editRenterModalDisclosure = useDisclosure();
  const handleError = useErrorHandling();

  useEffect(() => {
    api.renters.get(Number(id))
      .then(res => {
        setRenter(res.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [id]);

  return (
    <>
      <EditRenterModal disclosure={editRenterModalDisclosure} renter={renter} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link href={`/dashboard/renters/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Информация о арендаторе
          </Link>
          <Link href={`/dashboard/renters/${id}/offices`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Офисы в аренде
          </Link>
          <Link href={`/dashboard/renters/${id}/employees`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Сотрудники арендатора
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row w-full gap-4 items-center">
                <span className="font-medium">
                  {renter?.display_name}
                </span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Владелец</span>
                <span className="font-medium"><UserChip userId={renter?.holder || 0} /></span>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">ОГРН/ОГРНИП</span>
                <span className="font-medium">{renter?.orgn}</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Номер телефона</span>
                <span className="font-medium">{renter?.phone_number}</span>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Адрес электронной почты</span>
                <span className="font-medium">{renter?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
