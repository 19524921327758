import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { CreateBillModal } from "../../components/modals/bill/createBillModal";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "@/api";
import { Billing } from "@/api/billing";
import useErrorHandling from "@/hooks/useErrorHandling";
import { EditBillModal } from "../../components/modals/bill/editBillModal";
import { ColumnType } from "../../components/tables/tableTypes";
import { getCurrentMonth, getMonthInRussian } from "@/utils";
import { NewMonthHandbook } from "../../components/handbooks/service/monthHandbook";
import { NewYearHandbook } from "../../components/handbooks/service/yearHandbook";

export function BillingPage() {
  const [rawBillings, setRawBillings] = useState<Billing[] | null>(null);
  const [billings, setBillings] = useState<Billing[]>([]);
  const [billing, setBilling] = useState<Billing | null>(null);

  const [currentYear, setCurrentYear] = useState<string>("2024");
  const [currentMonth, setCurrentMonth] = useState<string>(getMonthInRussian(getCurrentMonth()));

  const handleError = useErrorHandling();

  const createBillingModalDisclosure = useDisclosure();
  const editBillingModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editBilling = (billing: Billing) => {
    setBilling(billing);
    editBillingModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.billings.list()
      .then(response => {
        setRawBillings(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createBillingModalDisclosure.isOpen,
    editBillingModalDisclosure.isOpen
  ]);

  useEffect(() => {
    setBillings(
      rawBillings?.filter(el => el.period == currentMonth && el.date.split("-")[0] == currentYear) || []
    );
  }, [
    rawBillings,
    currentMonth,
    currentYear
  ]);

  return (
    <>
      <CreateBillModal disclosure={createBillingModalDisclosure} />
      <EditBillModal disclosure={editBillingModalDisclosure} billing={billing} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Платежи</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
                <FaExpand />
              </Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createBillingModalDisclosure.onOpen()}
              >
                Создать биллинг
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <NewMonthHandbook
              onSelectionChange={(e) => { setCurrentMonth(e?.toString() || "Январь") }}
              selectedKey={currentMonth}
              children={<></>}
            />
            <NewYearHandbook
              onSelectionChange={(e) => { setCurrentYear(e?.toString() || "2024") }}
              selectedKey={currentYear}
              children={<></>}
            />
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              fullscreenDisclosure={fullscreenDisclosure}
              columns={[
                {
                  label: "Номер",
                  key: "number",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  label: "Дата",
                  key: "date",
                  type: ColumnType.Date,
                  sortable: true
                },
                {
                  label: "Плательщик",
                  key: "payer",
                  type: ColumnType.Renter,
                  sortable: true
                },
                {
                  label: "Счёт",
                  key: "provider",
                  type: ColumnType.Requisite,
                  sortable: true
                },
                {
                  label: "Сумма",
                  key: "amount",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  label: "Оплачено",
                  key: "paid",
                  type: ColumnType.Boolean,
                  sortable: true
                },
                {
                  label: "Описание",
                  key: "description",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editBilling(row); }
                    }
                  ]
                }
              ]}
              data={billings || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "number",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}