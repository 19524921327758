import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../../components/modals/modalBuilder";
import { api } from "@/api";
import { useNavigate, useParams } from "react-router-dom";
import { Lock } from "@/api/locks";
import { getIdFromLockSystemHandbook } from "@/utils/getIdFromHandbooks.ts";
import { Column, ColumnType } from "../tables/tableTypes";

interface IEditLockForm {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
}

const editLockFields: InputField[] = [
  {
    label: "Название замка",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Система",
    name: "lock_system",
    placeholder: "тут будет справочник, сейчас не нужно",
    type: "lock-system",
    isRequired: true
  }, {
    label: "Сервисный ID",
    name: "lock_service_id",
    placeholder: "1",
    type: "text",
    isRequired: true
  }
]

const viewItemColumns: Column[] = [
  {
    label: "Название замка",
    key: "display_name",
    type: ColumnType.String
  },
  {
    label: "Система",
    key: "lock_system",
    type: ColumnType.LockSystem
  },
  {
    label: "Сервисный ID",
    key: "lock_service_id",
    type: ColumnType.String
  }
];

export function ViewLockPage() {
  const { id } = useParams();
  const [lock, setLock] = useState<Lock | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.locks.get(Number(id))
      .then(response => {
        setLock(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных замка!");
      });
  }, [id]);

  const onSubmit = async (data: IEditLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
    const lockSystem = await getIdFromLockSystemHandbook(String(data.lock_system));

    api.locks.update(lock!.id, {
      display_name: data.display_name,
      lock_system: Number(lockSystem),
      lock_service_id: data.lock_service_id
    })
      .then(_data => {
        toast.success("Замок успешно обновлён!");
        resolve(true);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при редактировании замка!");
        resolve(false);
      });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locks.delete(lock!.id)
        .then(_data => {
          navigate("/dashboard/locks/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при удалении замка!");
          resolve(false);
        });
    });
  };

  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Замок"
          fields={editLockFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={lock} columns={viewItemColumns} />}
          defaultValues={{
            display_name: lock?.display_name,
            lock_system: lock?.lock_system,
            lock_service_id: lock?.lock_service_id,
          }} />
      )}
    </>
  );
}
