import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { User } from "@sentry/react";

export function NewUserHandbook(props: AutocompleteProps<User>) {
  const items = useAppSelector(state => state.handbooks.user);

  return (
    <Autocomplete
      defaultItems={items}
      label="Пользователь"
      placeholder="Выберите пользователя"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {`${item.first_name} ${item.last_name}`}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function UserHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.user);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Пользователь" 
      placeholder="Выберите пользователя"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.first_name} ${item.last_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}