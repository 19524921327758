import { Modal, ModalBody, ModalContent, ModalHeader, useDisclosure } from "@nextui-org/react";
import { getPortal } from "@/utils";

export function FullScreenModal({ children, disclosure }: { children: any, disclosure: ReturnType<typeof useDisclosure> }) {
  return (
    <Modal isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} portalContainer={getPortal()} size="full">
      <ModalContent>
        {(_onClose) => (
          <>
            <ModalHeader>Таблица (полноэкранный режим)</ModalHeader>
            <ModalBody>{ children }</ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}