import { Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "@/api";
import { toast } from "react-toastify";
import { TableBuilder } from "@/app/components/tables/tableBuilder.tsx";
import { User } from "@sentry/react";
import { ColumnType } from "@/app/components/tables/tableTypes.ts";

export function RenterEmployeesPage() {
  const { id } = useParams();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    api.renters.listEmployees(Number(id))
      .then(response => {
        setUsers(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке пользователей!");
      });
  }, [id]);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link href={`/dashboard/renters/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Информация о арендаторе
          </Link>
          <Link href={`/dashboard/renters/${id}/offices`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Офисы в аренде
          </Link>
          <Link href={`/dashboard/renters/${id}/employees`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Сотрудники арендатора
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-1 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row w-full gap-4 items-center">
                <span className="font-medium">
                  Пользователи арендатора
                </span>
              </div>
            </div>
            <TableBuilder
              displayOptions={false}
              displaySearch={false}
              removeWrapper={true}
              columns={[
                {
                  label: "Имя пользователя",
                  key: "username",
                  type: ColumnType.String
                },
                {
                  label: "Имя",
                  key: "first_name",
                  type: ColumnType.String
                },
                {
                  label: "Фамилия",
                  key: "last_name",
                  type: ColumnType.String
                },
                {
                  label: "Номер телефона",
                  key: "phone_number",
                  type: ColumnType.String
                }
              ]}
              data={users}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "username",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
