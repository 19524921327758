import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, Spinner, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { api } from "@/api";

export function InviteEmployeeModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [link, setLink] = useState<string>("");

  useEffect(() => {
    setLoading(true);

    api.businessProfiles.generateInviteLink()
      .then(res => {
        setLink("https://admin.elasticwork.space/invite/" + res.invite_hash);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при генерации пригласительной ссылки!");
      });
  }, [setLink, setLoading, disclosure.isOpen]);

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(_onClose) => (
            <>
              <ModalHeader className="pt-8">
                <span className="font-medium text-xl">Приглашение</span>
              </ModalHeader>
              <ModalBody>
                <div className="gap-3 flex flex-col">
                  <span className="text-sm font-medium text-red-950 dark:text-red-800">Вы собираетесь отправить приглашение на вступлению в Вашу компанию в роли сотрудника. Это значит, что получателю будут доступны все функции кабинета Вашей компании.</span>
                  <Spacer />
                  <div className="mt-2 flex flex-col w-full items-center">
                    {loading && (
                      <Spinner />
                    )}
                    {!loading && link !== undefined && (
                      <Input
                        value={link?.toString()}
                        defaultValue={link?.toString()}
                        isDisabled
                      />
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="pb-8">
                <CopyToClipboard text={link} onCopy={() => { toast.success("Ссылка скопирована в буфер обмена!") }}>
                    <Button size="md" color="primary" variant="solid" className="w-full" isDisabled={loading}>Скопировать приглашение</Button>
                  </CopyToClipboard>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}