import { Button, Spinner, Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";

export function NewInviteHashPage() {
  const { hash } = useParams() as { hash: string };
  const [validationResults, setValidationResults] = useState<boolean | null>(null);
  const handleError = useErrorHandling();
  const navigate = useNavigate();

  useEffect(() => {
    api.auth.validateToken()
      .then(_res => {
        setValidationResults(true);
        api.auth.connectBusinessProfile(hash)
          .then(res => {
            console.log(res);
            toast.success("Вы успешно приняли приглашение!");
            navigate("/dashboard/selectProfile");
          })
          .catch(err => {
            console.log(err);
            const { errorMessage } = handleError(err);
            toast.error(errorMessage);
          });
      })
      .catch(_err => {
        setValidationResults(false);
      });
  }, [hash]);

  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg max-lg:min-h-dvh flex flex-col p-4">
          <div className="mb-2">
            <img src="/logo.svg" width={"128"}></img>
          </div>
          <div className="mb-2">
            <span className="font-medium text-xl">Приглашение в компанию</span>
          </div>
          {validationResults == null ? (
            <div className="mb-4">
              <span>Подождите, пытаемся принять приглашение...</span>
            </div>
          ) : validationResults == false ? (
            <>
              <div className="mb-2">
                <span>Для принятия приглашения в компанию Вам необходимо авторизоваться в системе.</span>
              </div>
              <div className="max-lg:flex-grow"></div>
              <div className="flex flex-col gap-2 justify-self-end">
                <Button
                  as={Link}
                  href={`/auth/login?redirect_to=/invite/${hash}`}
                  color="primary"
                >
                  Войти
                </Button>
                <Button
                  as={Link}
                  href={`/auth/register?redirect_to=/invite/${hash}`}
                  color="primary"
                  variant="flat"
                >
                  Зарегистрироваться
                </Button>
              </div>
            </>
          ) : (
            <Spinner color="primary" />
          )}
        </div>
      </div>
    </>
  )
}