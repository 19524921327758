import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './app';
import './index.css';
import './desktop.css';
import * as Sentry from "@sentry/react";
import { YMInitializer } from 'react-yandex-metrika';

Sentry.init({
  dsn: "https://02bcbbc480cff0512c3e33a75c5bfd34@o4504363123933184.ingest.us.sentry.io/4507754871717888",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "api.elasticwork.space",
        /^https:\/\/api\.elasticwork\.space/,
      ]
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.elasticwork\.space/],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <YMInitializer accounts={[98839929]} version='2' />
    <App />
  </React.StrictMode>,
)