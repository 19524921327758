import { Button, Tab, Tabs, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import { api } from "@/api";
import { Office } from "@/api/offices";
import { CreateOfficeModal } from "../../components/modals/office/createOfficeModal";
import { EditOfficeModal } from "../../components/modals/office/editOfficeModal";
import useErrorHandling from "@/hooks/useErrorHandling";
import { AdminPermissions } from "@/api/auth/auth.ts";
import { ColumnType } from "../../components/tables/tableTypes";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

interface TabLocationInterface {
  id: number;
  display_name: string;
}

type TabLocation = TabLocationInterface | null;

export function OfficesPage() {
  const [rawOffices, setRawOffices] = useState<Office[] | null>(null);
  const [offices, setOffices] = useState<Office[] | null>(null);
  const [office, setOffice] = useState<Office | null>(null);
  const [tabs, setTabs] = useState<TabLocation[] | null>(null);
  const [activeTab, setActiveTab] = useState<any>("all");

  const cachedLocations = useAppSelector(state => state.handbooks.location);

  const handleError = useErrorHandling();

  const createOfficeModalDisclosure = useDisclosure();
  const editOfficeModalDisclosure = useDisclosure();
  const fullscreenDisclosure = useDisclosure();

  const editOffice = (office: Office) => {
    setOffice(office);
    editOfficeModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.offices.list()
      .then(async response => {
        setRawOffices(response.data);
      })
      .catch(err => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createOfficeModalDisclosure.isOpen,
    editOfficeModalDisclosure.isOpen
  ]);

  useEffect(() => {
    const locations: TabLocation[] = [];

    rawOffices?.forEach(office => {
      if (office.location) {
        if (!locations.find(loc => loc?.id == office.location)) locations.push({
          id: office.location,
          display_name: cachedLocations.find(l => l.id == office.location)?.display_name || "Локация " + office.location
        });
      }
    });

    setTabs(locations);
  }, [rawOffices]);

  useEffect(() => {
    if (activeTab === "all") return setOffices(rawOffices);
    const filteredOffices: Office[] = [...(rawOffices?.filter(office => office.location == activeTab) || [])];
    return setOffices(filteredOffices);
  }, [rawOffices, activeTab]);

  return (
    <>
      <CreateOfficeModal disclosure={createOfficeModalDisclosure} />
      <EditOfficeModal disclosure={editOfficeModalDisclosure} office={office} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Офисы</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
                <FaExpand />
              </Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createOfficeModalDisclosure.onOpen()}
              >
                Создать офис
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2 mb-4">
            <Tabs color="primary" variant="bordered" selectedKey={activeTab} onSelectionChange={setActiveTab}>
              <Tab key="all" title="Все локации" />
              {tabs?.map(tab => (
                <Tab key={tab?.id} title={tab?.display_name}></Tab>
              ))}
            </Tabs>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              fullscreenDisclosure={fullscreenDisclosure}
              displayOptions={false}
              removeWrapper
              columns={[
                {
                  key: "display_name",
                  label: "Название",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "area",
                  label: "Площадь",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  key: "price",
                  label: "Цена",
                  type: ColumnType.Number,
                  permissions: [AdminPermissions.ViewLandlordReports],
                  sortable: true
                },
                {
                  key: "floor",
                  label: "Этаж",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  key: "room_count",
                  label: "Комнаты",
                  type: ColumnType.Number,
                  sortable: true
                },
                {
                  key: "workplace_count",
                  label: "Рабочие места",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  key: "office_type",
                  label: "Тип офиса",
                  type: ColumnType.OfficeType,
                  sortable: true
                },
                {
                  key: "location",
                  label: "Локация",
                  type: ColumnType.Location,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editOffice(row) }
                    }
                  ]
                }
              ]}
              data={offices || []}
              rowsPerPage={10}
              defaultSortDescriptor={{
                column: "display_name",
                direction: "ascending"
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
