import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { api } from "@/api";
import { toast } from "react-toastify";
import { TemplateCreateParams } from "@/api/templates";
import FileUpload from "../../fileUpload/fileUpload";

interface PreloadForm {
  file: File[];
}

export function CreateTemplateModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const [stage, setStage] = useState<"preload" | "assign_meta">("preload");
  const [variables, setVariables] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const preloadForm = useForm<PreloadForm>();
  const metaForm = useForm<TemplateCreateParams>();

  const onPreloadSubmit = (data: PreloadForm) => {
    api.documents.preload(data.file[0])
      .then(res => {
        const set = new Set<string>(res.data.variables);
        ["document_price_text", "document_date"].forEach(key => set.delete(key));
        const variables = [...set];

        setVariables(variables);
        setStage("assign_meta");
        setFile(data.file[0]);
      })
      .catch(err => {
        toast.error("Произошла ошибка при анализе документа!");
        console.error(err);
      });
  };

  const onAssignMetaSubmit = (data: TemplateCreateParams) => {
    const payload = {
      file: file!,
      display_name: data.display_name,
      variables: data.variables,
    };

    payload.variables.document_date = "Дата заполнения (автоматически)";
    payload.variables.document_price_text = "Сумма буквами (автоматически)";

    api.documents.createTemplate(payload)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        toast.error("Произошла ошибка при создании шаблона!");
        console.error(err);
      });
  };

  return (
    <>
      <Modal isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} portalContainer={document.getElementById("main")!} backdrop="blur" scrollBehavior="outside">
        <ModalContent>
          {(_onClose) => (
            <>
              <ModalHeader className="pt-8">
                <span className="font-medium text-xl">Создание шаблона</span>
              </ModalHeader>
              <ModalBody>
                {stage === "preload" ? (
                  <form onSubmit={preloadForm.handleSubmit(onPreloadSubmit)}>
                    <span>Для начала, загрузите шаблон в формате docx:</span>

                    <FileUpload
                      control={preloadForm.control}
                      name="file"
                    />

                    <div className="pt-2 flex flex-row">
                      <Button type="submit">Продолжить</Button>
                    </div>
                  </form>
                ) : (
                  <form onSubmit={metaForm.handleSubmit(onAssignMetaSubmit)}>
                    <Input
                      {...metaForm.register('display_name', { required: true })}
                      placeholder="Введите название шаблона"
                      className="mb-4"
                    />

                    {variables.map((variable, index) => (
                      <div key={index} className="flex flex-row mb-2">
                        <Input
                          value={variable}
                          isDisabled
                          className="mr-2"
                        />
                        <Input
                          placeholder="Введите описание поля"
                          {...metaForm.register(`variables.${variable}`, { required: true })}
                        />
                      </div>
                    ))}

                    <div className="pt-2 flex flex-row">
                      <Button type="submit">Продолжить</Button>
                    </div>
                  </form>
                )}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}