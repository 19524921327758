import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LayoutNavbar from "../components/navbar/navbar";
import useDarkMode from "use-dark-mode";
import { toast, ToastContainer } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { api } from "@/api";
import { useAppDispatch } from "@/hooks/useAppDispatch.ts";
import { setPermissions, setUser } from "../store/user/reducer";
import { Navigation } from "../components/navigation/navigation";
import { setLocations, setLocks, setLockSystems, setOffices, setOfficeTypes, setPayments, setRentedOffices, setRenters, setRequisites, setTicketCategories, setUserRoles, setUsers } from "../store/handbooks/reducer";
import { Admin } from "@/api/auth/auth.ts";
import { Button, NextUIProvider } from "@nextui-org/react";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { NotificationIcon } from "../store/notifications/types";
import { addNotification } from "../store/notifications/reducer";
import ym from "react-yandex-metrika";

export function Layout({ children }: { children?: React.ReactNode }) {
  const darkMode = useDarkMode(false);
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [user, setDUser] = useState<Admin | null>(null);
  const slocation = useLocation();

  useEffect(() => {
    document.querySelector("body")?.classList.remove("dark", "text-foreground", "bg-background");
    document.querySelector("body")?.classList.add(darkMode.value ? "dark" : "white", "text-foreground", "bg-background");
  }, [darkMode.value]);

  useEffect(() => {
    api.notifications.list()
      .then(res => {
        res.data.forEach(notification => {
          dispatch(addNotification({
            id: notification.id,
            icon: NotificationIcon.INFO,
            title: notification.title,
            message: notification.message,
            datetime: notification.created_at,
            is_read: notification.is_read
          }));
        });
      })
      .catch(err => {
        console.log(err);
      });

    const url = slocation.pathname + slocation.search;
    ym('hit', url);

    api.auth.getCurrentAdminUserInfo().then(data => {
      setDUser(data.data);
      dispatch(setUser(data.data));
    });

    api.auth.getMyPermissions().then(data => {
      const permissions: string[] = [];
      permissions.push(...Object.keys(data.data.find(bp => bp.business_profile.id == businessProfile.id)?.permissions || []));

      dispatch(setPermissions(permissions));
    });

    if (location.href.indexOf("selectProfile") == -1 && !localStorage.getItem("businessProfileId")) {
      navigate("/dashboard/selectProfile");
    }

    Promise.allSettled([
      api.tickets.categories.list(),
      api.locations.list(),
      api.locks.list(),
      api.lockSystems.list(),
      api.offices.list(),
      api.officeTypes.list(),
      api.renters.list(),
      api.users.list(),
      api.userRoles.list(),
      api.requisites.list(),
      api.rentedOffices.list(),
      api.billings.list()
    ])
      .then(results => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            switch (index) {
              case 0:
                //@ts-ignore
                dispatch(setTicketCategories(result.value.data));
                break;
              case 1:
                //@ts-ignore
                dispatch(setLocations(result.value.data));
                break;
              case 2:
                //@ts-ignore
                dispatch(setLocks(result.value.data));
                break;
              case 3:
                //@ts-ignore
                dispatch(setLockSystems(result.value.data));
                break;
              case 4:
                //@ts-ignore
                dispatch(setOffices(result.value.data));
                break;
              case 5:
                //@ts-ignore
                dispatch(setOfficeTypes(result.value.data));
                break;
              case 6:
                //@ts-ignore
                dispatch(setRenters(result.value.data));
                break;
              case 7:
                //@ts-ignore
                dispatch(setUsers(result.value.data));
                break;
              case 8:
                //@ts-ignore
                dispatch(setUserRoles(result.value.data));
                break;
              case 9:
                //@ts-ignore
                dispatch(setRequisites(result.value.data));
                break;
              case 10:
                //@ts-ignore
                dispatch(setRentedOffices(result.value.data));
                break;
              case 11:
                //@ts-ignore
                dispatch(setPayments(result.value.data));
                break;
              default:
                break;
            }
          } else {
            console.log(`Handbook ${index} failed:`, result.reason);
          }
        });
      });
  }, [slocation.pathname]);

  const linkTelegram = useCallback(() => {
    api.auth.generateTelegramLink()
      .then(data => {
        location.href = data.data.link;
      })
      .catch(err => {
        console.error(err);
        toast.error("У нас произошли некоторые проблемы с привязкой вашего аккаунта к Telegram. Попробуйте позже или обратитесь в поддержку");
      })
  }, [user]);

  return (
    <>
      <NextUIProvider navigate={navigate}>
        <main id="main" className={`${darkMode.value ? 'dark bg-black' : 'bg-zinc-100'} text-foreground`} >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <div className="layout">
            <LayoutNavbar />
            <div className="layout__content lg:max-h-screen">
              {(typeof user?.telegram == null || user?.telegram == 0 || user?.telegram == null) && (
                <div className="w-full bg-primary-100 flex flex-col gap-2 rounded-xl p-4 mb-4">
                  <span className="text-sm">Заметили, что у вас всё ещё не привязан Telegram. А зря - у нас есть удобный Telegram-бот, облегчающий работу с кабинетом.</span>
                  <Button variant="flat" color="primary" size="sm" onClick={linkTelegram} className="mt-2 max-w-fit">Класс, хочу попробовать</Button>
                </div>
              )}
              <Navigation />
              {children || <Outlet />}
            </div>
          </div>
        </main>
      </NextUIProvider>
    </>
  )
}