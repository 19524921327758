import { Chip } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserRole } from "@/api/userRoles/userRoles.ts";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

export function RoleChip({ roleId }: { roleId: number }) {
  const [item, setItem] = useState<UserRole | null>(null);
  const items = useAppSelector(state => state.handbooks.userRole);

  useEffect(() => {
    setItem(items.find(el => el.id == roleId) || null);
  }, [roleId, items]);

  return (
    <>
      {item !== null && (
        <Chip as={Link} to={"/dashboard/userRoles"} variant="dot" color="primary">{item?.name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};