import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "@/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";
import { useState } from "react";

const createBusinessProfileFields: InputField[] = [
  {
    label: "Название профиля",
    name: "display_name",
    placeholder: "ElasticWork.Space",
    type: "text", 
    isRequired: true
  }, {
    label: "Адрес",
    name: "legal_address",
    placeholder: "г. Москва, ул...",
    type: "text", 
    isRequired: true
  }, {
    label: "ИНН",
    name: "inn",
    placeholder: "",
    type: "text", 
    isRequired: true
  }, {
    label: "ОГРН",
    name: "ogrn",
    placeholder: "",
    type: "text", 
    isRequired: true
  }
]

export function CreateBusinessProfileModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: { display_name: string, legal_address: string, inn: string, ogrn: string }) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.businessProfiles.create({
        display_name: data.display_name,
        legal_address: data.legal_address,
        inn: data.inn,
        ogrn: data.ogrn
      })
        .then(_data => {
          disclosure.onClose();
          navigate("/dashboard/selectProfile");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание бизнес-профиля" modalErrors={modalErrors} fields={createBusinessProfileFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}