import React, { useEffect, useState, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  useDisclosure,
} from "@nextui-org/react";
import {
  BarChart,
  Bar,
  Cell,
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { toast } from "react-toastify";

import { TableBuilder } from "@/app/components/tables/tableBuilder";
import { RenterChip } from "@/app/components/chips/renterChip";
import { OfficeChip } from "@/app/components/chips/officeChip";
import { RentedOfficeChip } from "@/app/components/chips/rentedOfficeChip";
import { PaymentChip } from "@/app/components/chips/paymentChip";
import { CreateRentedOfficeByOgrnModal } from "@/app/components/modals/office/createRentedOfficeByOgrnModal";
import { CreateBillFromFinanceModal } from "@/app/components/modals/bill/createBillFromFinanceModal";

import {
  getCurrentMonth,
  getMonthInEnglish,
  getRussianMonthFromEnglish,
} from "@/utils";
import { SummaryReport } from "@/api/analytics";
import { api } from "@/api";
import useErrorHandling from "@/hooks/useErrorHandling";
import { ColumnType } from "@/app/components/tables/tableTypes";
import { useAppSelector } from "@/hooks/useAppSelector.ts";
import { NewLocationHandbook } from "@/app/components/handbooks/locationHandbook";
import { NewYearHandbook } from "@/app/components/handbooks/service/yearHandbook";
import { FaDownload } from "react-icons/fa6";

interface SummaryRow {
  payment_plan: number;
  payment_actual: number;
  deposit: number;
  sale: number;
  potential_earnings: number;
  workplaces: number;
  price_per_workplace: number;
  potential_price_per_workplace: number;
  price_per_sqm: number;
  potential_price_per_sqm: number;
  total_square_meters: number;
}

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      className="font-medium"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function FinancePage() {
  const handleError = useErrorHandling();
  const firstLocation = useAppSelector((state) => state.handbooks.location[0]?.id || 0);
  const offices = useAppSelector(state => state.handbooks.office);

  const [activeTab, setActiveTab] = useState<string>(getMonthInEnglish(getCurrentMonth()));
  const [rawData, setRawData] = useState<SummaryReport | null>(null);
  const [activeLocation, setActiveLocation] = useState<string>(firstLocation.toString());
  const [activeYear, setActiveYear] = useState<string>(String(new Date().getFullYear()));
  const [createPaymentProps, setCreatePaymentProps] = useState<{
    officeId: number;
    renterId: number;
  }>({ officeId: 0, renterId: 0 });
  
  useEffect(() => {
    setActiveLocation(firstLocation.toString());
    setActiveYear(String(new Date().getFullYear()));
    setActiveTab(getMonthInEnglish(getCurrentMonth()));
  }, [firstLocation]);

  const createRentedOfficeDisclosure = useDisclosure();
  const createPaymentDisclosure = useDisclosure();

  useEffect(() => {
    if (!activeTab || createPaymentDisclosure.isOpen || createRentedOfficeDisclosure.isOpen) return;

    api.analytics
      .summary(getRussianMonthFromEnglish(activeTab), activeYear, Number(activeLocation))
      .then((res) => setRawData(res.data))
      .catch((err) => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    activeTab,
    activeLocation,
    activeYear,
    handleError,
    createPaymentDisclosure.isOpen,
    createRentedOfficeDisclosure.isOpen,
  ]);

  const data = useMemo(() => {
    if (!rawData) return null;

    const sortedRentPlan = [...rawData.rent_plan].sort((a, b) =>
      a.office_name < b.office_name ? -1 : a.office_name > b.office_name ? 1 : 0
    );

    return {
      ...rawData,
      rent_plan: sortedRentPlan,
    };
  }, [rawData]);

  const summaryRow = useMemo<SummaryRow | null>(() => {
    if (!data) return null;

    const deposit = data.rent_plan.reduce((total, item) => total + (item.deposit ?? 0), 0);
    const payment_plan = data.rent_plan.reduce((total, item) => total + (item.price ?? 0), 0);
    const payment_actual = data.actual_payments.reduce(
      (total, item) => total + (item.total_paid ?? 0),
      0
    );
    const workplaces = data.rent_plan.reduce((total, item) => total + (item.workplace_count ?? 0), 0);
    const potential_earnings = data.rent_plan.reduce(
      (total, item) => total + (item.potential_earning_price ?? 0),
      0
    );

    const validPotentialPricesPerSqm = data.rent_plan.filter((item) => item.potential_price_per_sqm !== null);
    const potential_price_per_sqm =
      validPotentialPricesPerSqm.reduce((total, item) => total + (item.potential_price_per_sqm ?? 0), 0) /
      validPotentialPricesPerSqm.length || 0;

    const validPotentialPricesPerWorkplace = data.rent_plan.filter(
      (item) => item.potential_workspace_earning_price !== null
    );
    const potential_price_per_workplace =
      validPotentialPricesPerWorkplace.reduce(
        (total, item) => total + (item.potential_workspace_earning_price ?? 0),
        0
      ) / validPotentialPricesPerWorkplace.length || 0;

    const validPricesPerSqm = data.rent_plan.filter((item) => item.price_per_sqm !== null);
    const price_per_sqm =
      validPricesPerSqm.reduce((total, item) => total + (item.price_per_sqm ?? 0), 0) /
      validPricesPerSqm.length || 0;

    const validPricesPerWorkplace = data.rent_plan.filter((item) => item.price_per_workplace !== null);
    const price_per_workplace =
      validPricesPerWorkplace.reduce((total, item) => total + (item.price_per_workplace ?? 0), 0) /
      validPricesPerWorkplace.length || 0;

    const total_square_meters =
      offices.reduce((total, item) => total + (item.area ?? 0), 0);

    return {
      deposit,
      payment_actual,
      payment_plan,
      sale: 0,
      workplaces,
      potential_earnings,
      potential_price_per_sqm,
      potential_price_per_workplace,
      price_per_sqm,
      price_per_workplace,
      total_square_meters
    };
  }, [data]);

  const pieData = [
    { name: "Мясницкая", value: 2800000 },
    { name: "Тверская", value: 1200000 },
    { name: "Охотный", value: 5600000 },
  ];

  const barData = [
    {
      name: "Январь",
      uv: 4200,
      pv: 2500,
      amt: 2300,
      qv: 1350,
    },
    {
      name: "Февраль",
      uv: 3200,
      pv: 1400,
      amt: 2200,
      qv: 1450,
    },
    {
      name: "Март",
      uv: 2100,
      pv: 9900,
      amt: 2400,
      qv: 1670,
    },
    {
      name: "Апрель",
      uv: 2600,
      pv: 3700,
      amt: 1950,
      qv: 1200,
    },
    {
      name: "Май",
      uv: 1900,
      pv: 4500,
      amt: 2150,
      qv: 1830,
    },
    {
      name: "Июнь",
      uv: 2400,
      pv: 3850,
      amt: 2450,
      qv: 1100,
    },
    {
      name: "Июль",
      uv: 3500,
      pv: 4200,
      amt: 2050,
      qv: 1760,
    },
    {
      name: "Август",
      uv: 2700,
      pv: 3400,
      amt: 2300,
      qv: 1580,
    },
    {
      name: "Сентябрь",
      uv: 3100,
      pv: 4000,
      amt: 2200,
      qv: 1890,
    },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <>
      <CreateBillFromFinanceModal
        disclosure={createPaymentDisclosure}
        paymentProps={createPaymentProps}
      />
      <CreateRentedOfficeByOgrnModal disclosure={createRentedOfficeDisclosure} />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col-reverse md:flex-row gap-2">
          <div className="flex flex-col gap-2 md:flex-grow">
            <div className="bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
              <div className="flex flex-row w-full items-center mb-2">
                <span className="font-medium">Топ локаций по аренде</span>
              </div>
              <TableBuilder
                removeWrapper
                displayOptions={false}
                displaySearch={false}
                data={[]}
                columns={[
                  {
                    key: "location",
                    label: "Локация",
                    type: ColumnType.Location,
                  },
                  {
                    key: "planned",
                    label: "План",
                    type: ColumnType.String,
                  },
                  {
                    key: "paid",
                    label: "Факт",
                    type: ColumnType.String,
                  },
                  {
                    key: "last_to_pay",
                    label: "Осталось (план)",
                    type: ColumnType.String,
                  },
                ]}
              />
            </div>

            {/* Rent Trend for the Year */}
            <div className="bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
              <div className="flex flex-row w-full items-center mb-2">
                <span className="font-medium">Тенденция аренды за год</span>
              </div>
              <div className="flex flex-row gap-2 mb-4">
                <Autocomplete label="Год" defaultSelectedKey="2024" variant="bordered" size="sm">
                  <AutocompleteItem key="2024">2024 год</AutocompleteItem>
                </Autocomplete>
              </div>
              <ResponsiveContainer height={250} width="100%">
                <BarChart
                  data={barData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "12px",
                      border: "none",
                    }}
                  />
                  <Bar dataKey="pv" stackId="a" fill="#0088FE" />
                  <Bar dataKey="uv" stackId="a" fill="#00C49F" />
                  <Bar dataKey="amt" stackId="a" fill="#FFBB28" />
                  <Bar dataKey="qv" stackId="a" fill="#FF8042" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="flex flex-col gap-2 md:min-w-96">
            <div className="bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
              <div className="flex flex-row w-full items-center mb-2">
                <span className="font-medium">Суммарная выручка</span>
              </div>
              <div className="flex flex-col justify-center items-center gap-2">
                <PieChart width={200} height={200}>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((_entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
                <div className="flex flex-row gap-4 flex-wrap">
                  {pieData.map((entry, index) => {
                    const color = COLORS[index % COLORS.length];
                    return (
                      <div key={`legend-${index}`} className="flex flex-row gap-1 items-center justify-center">
                        <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }} />
                        <span className="font-medium">{entry.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
              <div className="flex flex-row w-full items-center mb-2">
                <span className="font-medium">Потенциальная выручка</span>
              </div>
              <TableBuilder
                removeWrapper
                displayOptions={false}
                displaySearch={false}
                columns={[
                  {
                    key: "location_id",
                    type: ColumnType.Location,
                    label: "Локация",
                    sortable: true,
                  },
                  {
                    key: "potential_earnings",
                    type: ColumnType.Custom,
                    label: "Потенциальный доход",
                    sortable: true,
                    render(value: number) {
                      return Number(value).toLocaleString("ru");
                    },
                  },
                ]}
                data={data?.potential_earnings || []}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-2">
            {/* @ts-ignore */}
            <NewLocationHandbook
              errorMessage={""}
              isInvalid={false}
              onSelectionChange={(key: any) => setActiveLocation(key)}
              selectedKey={activeLocation}
            />
            {/* @ts-ignore */}
            <NewYearHandbook
              errorMessage={""}
              isInvalid={false}
              onSelectionChange={(key: any) => setActiveYear(key)}
              selectedKey={activeYear}
            />
          </div>
          <div className="flex flex-row gap-2">
            <Button
              color="primary"
              variant="flat"
              startContent={<FaDownload />}
            >
              Экспорт в Excel
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <Tabs variant="light" selectedKey={activeTab} onSelectionChange={(e) => setActiveTab(e.toString())}>
            <Tab key="january" title="Январь" />
            <Tab key="february" title="Февраль" />
            <Tab key="march" title="Март" />
            <Tab key="april" title="Апрель" />
            <Tab key="may" title="Май" />
            <Tab key="june" title="Июнь" />
            <Tab key="july" title="Июль" />
            <Tab key="august" title="Август" />
            <Tab key="september" title="Сентябрь" />
            <Tab key="october" title="Октябрь" />
            <Tab key="november" title="Ноябрь" />
            <Tab key="december" title="Декабрь" />
          </Tabs>

          <Table isHeaderSticky removeWrapper>
            <TableHeader>
              <TableColumn key="office">
                Офис
              </TableColumn>
              <TableColumn key="renter">
                Арендатор
              </TableColumn>
              <TableColumn key="potential_income">
                <div className="flex flex-col">
                  <span>Потенциальная выручка</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.potential_earnings ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="payment_plan">
                <div className="flex flex-col">
                  <span>План оплаты</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.payment_plan ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="planned_payment_date">
                <div className="flex flex-col">
                  <span>Плановая дата оплаты</span>
                </div>
              </TableColumn>
              <TableColumn key="actual_payment">
                <div className="flex flex-col">
                  <span>Фактическая оплата</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.payment_actual ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="actual_payment_date">
                <div className="flex flex-col">
                  <span>Фактическая дата оплаты</span>
                </div>
              </TableColumn>
              <TableColumn key="deposit">
                <div className="flex flex-col">
                  <span>Депозит</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.deposit ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="discount">
                <div className="flex flex-col">
                  <span>Скидка</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.sale ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="work_places">
                <div className="flex flex-col">
                  <span>Рабочие места</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.workplaces ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="price_per_place">
                <div className="flex flex-col">
                  <span>Цена за рабочее место</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.price_per_workplace ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="potential_price_per_place">
                <div className="flex flex-col">
                  <span>Пот. цена за раб. место</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.potential_price_per_workplace ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="square_meters">
                <div className="flex flex-col">
                  <span>Площадь (м2)</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.total_square_meters ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="price_per_square">
                <div className="flex flex-col">
                  <span>Цена за м2</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.price_per_sqm ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
              <TableColumn key="potential_price_per_square">
                <div className="flex flex-col">
                  <span>Пот. цена за м2</span>
                  <span className="text-foreground-900">
                    {(summaryRow?.potential_price_per_sqm ?? 0).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </TableColumn>
            </TableHeader>

            <TableBody>
              {(data?.rent_plan.map((row, index) => {
                const actualPaymentForOffice =
                  data?.actual_payments.find((el) => el.office_id === row.office_id)?.total_paid ?? 0;
                const isPartialPayment = actualPaymentForOffice < (row.price ?? 0);
                const rowClassName = [
                  "h-16",
                  isPartialPayment ? "bg-yellow-100 bg-opacity-5 dark:bg-yellow-300 dark:bg-opacity-5" : "",
                  index < (data?.rent_plan.length ?? 0) - 1 ? "border-b-2 border-gray-300 dark:border-zinc-800" : "",
                ]
                  .join(" ")
                  .trim();

                return (
                  <TableRow key={`row-${row.office_id}-${index}`} className={rowClassName}>
                    <TableCell>
                      <OfficeChip officeId={row.office_id} />
                    </TableCell>
                    <TableCell>
                      <RenterChip renterId={row.renter_id ?? 0} />
                    </TableCell>
                    <TableCell>
                      {(row.potential_earning_price ?? 0).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <RentedOfficeChip
                        displayName={(row.price ?? 0).toFixed(2)}
                        rentedOfficeId={row.rent_id ?? 0}
                        fallbackCallback={createRentedOfficeDisclosure.onOpen}
                      />
                    </TableCell>
                    <TableCell>н/д</TableCell>
                    <TableCell>
                      <PaymentChip
                        displayAmount={actualPaymentForOffice.toFixed(2)}
                        plannedAmount={row.price}
                        paymentId={
                          data?.actual_payments.find((el) => el.office_id === row.office_id)?.billing_id ?? 0
                        }
                        fallbackCallback={() => {
                          setCreatePaymentProps({ officeId: row.office_id ?? 0, renterId: row.renter_id ?? 0 });
                          createPaymentDisclosure.onOpen();
                        }}
                      />
                    </TableCell>
                    <TableCell>н/д</TableCell>
                    <TableCell>{(row.deposit ?? 0).toFixed(2)}</TableCell>
                    <TableCell>н/д</TableCell>
                    <TableCell>{(row.workplace_count ?? 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.price_per_workplace ?? 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.potential_workspace_earning_price ?? 0).toFixed(2)}</TableCell>
                    <TableCell>{(offices.find(el => el.id == row.office_id)?.area ?? 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.price_per_sqm ?? 0).toFixed(2)}</TableCell>
                    <TableCell>{(row.potential_price_per_sqm ?? 0).toFixed(2)}</TableCell>
                  </TableRow>
                );
              })) ?? []}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
