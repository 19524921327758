import { Button, Input, Link } from "@nextui-org/react";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { api } from "@/api";
import { toast } from "react-toastify";
import useErrorHandling from "@/hooks/useErrorHandling";

export function NewLoginPage() {
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback((data: any) => {
    api.auth.login(data.username, data.password )
      .then((response) => {
        localStorage.clear();
        localStorage.setItem("access_token", response.access_token);
        navigate(searchParams.get("redirect_to") || "/dashboard");
      })
      .catch((err) => {
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        console.log(err);
      });
  }, [navigate]);

  return (
    <>
      <div className="w-screen h-dvh min-h-dvh flex flex-col items-center justify-center">
        <div className="w-screen max-w-lg max-lg:max-w-screen-lg max-lg:min-h-dvh flex flex-col p-4">
          <div className="mb-4 self-center">
            <img src="/logo.svg" width={"196"} alt="Logo" />
          </div>
          <div className="flex flex-col h-full gap-2 justify-self-end">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full gap-2">
              <Input
                {...register("username", { required: true })}
                label="Имя пользователя"
                placeholder="Введите имя пользователя"
                variant="bordered"
                isInvalid={!!errors.username}
                errorMessage={String(errors.username?.message || "")}
              />
              <Input
                {...register("password", { required: true })}
                label="Пароль"
                placeholder="Введите ваш пароль"
                type="password"
                variant="bordered"
                isInvalid={!!errors.password}
                errorMessage={String(errors.password?.message || "")}
              />
              <div className="self-end">
                <Link color="foreground" size="sm" className="font-medium" href="/auth/reset">Забыли пароль?</Link>
              </div>
              <div className="max-lg:flex-grow"></div>
              <Button color="primary" className="w-full font-semibold" type="submit">
                Войти
              </Button>
              <div className="mt-4 flex w-full flex-col items-center">
                <span>Нет аккаунта?</span>
                <Link href="/auth/register" className="font-medium" showAnchorIcon>Зарегистрируйтесь</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
