import rootStore from "@/app/store";
import crud, { ApiResponse } from "../crud"

export interface SharedLock {
  id: number;
  lock_id: number;
  location_id: number;
}

export interface SharedLockCreate {
  lock_id: number;
  location_id: number;
}

export interface SharedLockUpdate {
  lock_id: number;
  location_id: number;
}

export function SharedLocksAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/shared-locks/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<SharedLock[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/shared-locks/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<SharedLock>;
    },
    create: async (lockData: SharedLockCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/shared-locks/?business_profile_id=${businessProfileId}`, lockData, true) as ApiResponse<SharedLock>;
    },
    update: async (id: number, lockData: SharedLockUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/shared-locks/${id}?business_profile_id=${businessProfileId}`, lockData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/shared-locks/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
