import { Chip } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { TicketCategory } from "@/api/tickets/tickets.ts";
import { useAppSelector } from "@/hooks/useAppSelector.ts";

export function CategoryChip({ categoryId }: { categoryId: number }) {
  const [item, setItem] = useState<TicketCategory | null>(null);
  const items = useAppSelector(state => state.handbooks.ticketCategory);

  useEffect(() => {
    setItem(items.find(el => el.id == categoryId) || null);
  }, [categoryId, items]);

  return (
    <>
      {item !== null && (
        <Chip className="cursor-pointer" variant="dot" color="primary">{item?.display_name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};